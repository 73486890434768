

























































import { defineComponent } from '~/utils/nuxt3-migration'
import CImg from '~/components/shared/configurable/image/CImg.vue'

export default defineComponent({
  components: { CImg }
})

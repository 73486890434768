var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.myClassifiedsLinks.length > 0)?_c('div',{staticClass:"tw-flex tw-gap-2",class:[_vm.flex ? 'tw-flex-wrap tw-flex-row' : 'tw-flex-col']},_vm._l((_vm.myClassifiedsLinks),function(link){return _c('div',{key:link.seoUrl,staticClass:"tw-flex tw-justify-between tw-items-center my-clsfd-link"},[_c('CLink',{staticClass:"tw-flex tw-justify-between tw-items-center tw-flex-1",class:[
        {
          selected: link.selected,
          'sub-item side-btn tw-group': _vm.inSidebar,
          'sub-link has-icon my-clsfds-link': _vm.inSubmenu,
          'tw-bg-grey-50': _vm.inSubmenu && link.type === 'deleted',
          'tw-rounded-md tw-p-2 search-link tw-text-grey-900':
            !_vm.inSidebar && !_vm.inSubmenu,
          'flex-mode': _vm.flex
        }
      ],attrs:{"href":_vm.getLink(link.seoUrl)},on:{"click":function($event){return _vm.linkClicked($event, link)}}},[_c('div',{staticClass:"tw-flex tw-items-center icon-and-label",class:{ 'tw-w-full': _vm.inSubmenu }},[(link.type === 'top_level_category')?_c('CCategoryIcon',{staticClass:"c-icon tw-flex",class:{
            'sub-icon !tw-mr-1': _vm.inSubmenu,
            '!tw-mr-2 tw-text-primary': !_vm.inSidebar && !_vm.inSubmenu
          },attrs:{"category-ids":link.categoryIds,"in-sidebar":_vm.inSidebar}}):_c(_vm.inSidebar ? 'FeedSidebarIconContainer' : 'span',{tag:"component",class:{
            'sub-icon !tw-mr-1': _vm.inSubmenu,
            'all-icon-container tw-mr-2': !_vm.inSidebar && !_vm.inSubmenu
          }},[(_vm.getGenericLinkIcon(link.type).name)?_c('CIcon',{staticClass:"tw-flex tw-text-base",class:{ 'tw-text-primary': !_vm.inSidebar && !_vm.inSubmenu },attrs:{"icon":_vm.getGenericLinkIcon(link.type)}}):_c('CIcon',{staticClass:"tw-flex is-fa tw-text-base",class:{ 'tw-text-primary': !_vm.inSidebar && !_vm.inSubmenu },attrs:{"icon":_vm.getGenericLinkIcon(link.type)}})],1),_vm._v(" "),(_vm.inSubmenu)?_c('div',{staticClass:"text-with-label"},[_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(link.label))]),_vm._v(" "),(link.count)?_c('span',{staticClass:"label"},[_vm._v(_vm._s(link.count))]):_vm._e()]):_c('span',{staticClass:"max-2-lines-text !tw-mr-2",class:{
            'tw-text-grey-800 group-hover:tw-text-blue-700 tw-font-bold': _vm.inSidebar,
            'search-link__label': !_vm.inSidebar && !_vm.inSubmenu
          }},[_vm._v(_vm._s(link.label))])],1),_vm._v(" "),(!_vm.inSubmenu && link.count)?_c('CCount',{attrs:{"count":link.count}},[_vm._v("\n        "+_vm._s(link.count)+"\n      ")]):_vm._e()],1)],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }